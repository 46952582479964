.testShow .MuiFormLabel-root {
  font-size: 18px;
}

.ra-field-undefined {
  left: unset;
  top: unset;
}

.divider {
  margin-top: 10px;
}

.userDataText {
  display: inline-block;
  width: 150px;
}

.flexyDataText {
  display: inline-block;
  min-width: 150px !important;
  width: unset !important;
  margin-right: 10px;
}

.text .MuiTypography-body2 {
  font-size: 16px;
}

.text--purple {
  color: #61419b;
}
