.testShow .MuiFormLabel-root {
  font-size: 18px;
}

.ra-field-undefined {
  left: unset;
  top: unset;
}

.divider {
  margin-top: 10px;
}

.extraMarginBottom {
  margin-bottom: 30px;
}

.extraMarginTop {
  margin-top: 30px;
}

.extraMarginLeft {
  margin-left: 30px;
}

.userDataText {
  display: inline-block;
  width: 150px;
}

.text .MuiTypography-body2 {
  font-size: 16px;
}

.text--purple {
  color: #61419b;
}

//List - diagnosis
.listHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.listHeader--additionalAllergens {
  margin-top: 20px;
}

.listHeaderItem {
  padding: 0 10px;
  width: 25%;
  text-align: center;

  &:first-child {
    padding-left: 0;
    text-align: left;
  }
}

.list {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.listItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.listItemContent {
  width: 25%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    text-align: left;
    justify-content: start;
  }
}

.noMarginNoPadding {
  margin: 0;
  padding: 0;
}

.fullWidth {
  width: 100%;

  & > .MuiCardContent-root {
    width: 100%;
  }
}

.treatmentBlock {
  padding: 20px 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  .treatmentBlockUploadLabel {
    width: 50%;
    padding-top: 8px;
  }

  &.medication {
    justify-content: flex-start;

    .medicationColumn {
      padding-right: 20px;
    }

    .medicationColumn-trashCan {
      padding-right: 0;
      margin-left: auto;
    }
  }

  &.notes {
    padding: 0;
  }
}

.treatmentBlock-text {
  display: flex;
  align-items: center;
}

.therapyTypeCheckboxes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
}

.monthsSelection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 0 0 40px;

  & > .monthCheckbox {
    flex-basis: 16%;
  }
}

button.deleteButton {
  width: 32px;
  min-width: 32px;
  padding: 0;
  margin: 0;
}

.formWrapper {
  max-width: 1000px;
}
