.box {
  position: 'absolute';
  top: '50%';
  left: '50%';
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: '#fff';
  border: '2px solid #000';
}

.button-wrapper {
  display: flex;
  width: 100%;
  margin-top: 20px;
  justify-content: space-between;
}
