.label {
  color: #666666;
}

.content {
  display: flex;
  margin-bottom: 20px;
}

.result {
  align-items: center;
  border-radius: 50%;
  display: block;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 50px;
  margin: 5px auto 0;
  border: 1px solid #000000;
}

.itemWrapper {
  margin-right: 30px;
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.itemWrapper--stringValue {
  width: 200px;
}

.itemWrapper--testName {
  align-items: flex-start;
}

.text {
  margin-top: 15px;
}

.text--stringValue {
  padding: 5px 10px;
  border-radius: 5px;
}
