.doctors-report {
  margin-bottom: 30px;
}

.doctors-report__label {
  display: flex;
  font-size: 18px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 5px;
}

.doctors-report__name-wrapper {
  margin-bottom: 20px;
}

.doctors-report__report {
  display: block;
  max-width: 600px;
  line-height: 140%;
}
