[class*='RaAppBar'] [class*='MuiBox-root'] + button svg:first-child {
  display: none;
}

form {
  .hidden-input {
    display: none;
  }
}

header.MuiPaper-root button[aria-label='Refresh'] {
  display: none;
}

.fade {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  background: #000;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.simple-form {
  .list-page {
    width: 100%;
  }

  .MuiToolbar-regular {
    background-color: transparent;
  }
}

.grid-wrapper {
  overflow-x: auto;
  max-width: 78vw;

  &-min {
    max-width: fit-content;
  }

  &-max {
    max-width: 85vw;
  }

  @media screen and (max-width: 768px) {
    &-max {
      max-width: 80vw;
    }
  }
}

.grid-wrapper ~ .edit-page .MuiCard-root {
  overflow: initial;
}

.simple-form .grid-wrapper {
  overflow: auto;
  height: 700px;
}

[class*='RaSelectInput'] {
  min-width: 200px;
}

.form-create {
  min-width: 575px;

  [class*='RaCardContentInner-root'] {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
.prescription-show {
  position: relative;
}
.ra-field-undefined {
  position: relative;
  left: 109px;
  top: 43px;
}
.show-layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.show-layout__field {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 0.2px solid rgb(172, 172, 172);
  padding: 10px 0px;
  *:not(.video-field):not(.icon-copy) {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }
  .show-layout__text {
    display: flex;
    gap: 20px;
    span {
      font-size: 18px;
    }
  }

  .label {
    color: rgba(97, 65, 155, 1);
    font-size: 24px;
    font-weight: 600;
  }
  .video-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 22px;
    min-width: 50%;
    .show-layout__field,
    .MuiToolbar-regular {
      flex-direction: row;
    }
    .MuiFormGroup-row > * {
      flex-direction: row;
      span {
        flex: unset;
      }
    }
  }
}
.MuiCardContent-root .show-layout__copy {
  .show-layout__text {
    flex-direction: row;
  }
}

.MuiCardContent-root .show-layout__nested {
  flex-direction: column;
  align-items: flex-start;

  .show-layout__copy {
    width: 100%;
    flex-direction: row;

    &:nth-child(2) {
      margin-top: 20px;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }

  .show-layout__label-with-addition {
    flex-direction: row;
  }
}

.icon-copy {
  width: 30px;
  height: 30px;
  background-image: url(/images/icon-copy.png);
  background-size: cover;
  cursor: pointer;
}

.show-layout__label-addition {
  margin-left: 5px;
}

.RaSidebar-root-29 {
  height: auto !important;
}
