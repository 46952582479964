.sidebar {
  width: 50%;
  margin-left: 20px;
}

.sidebar__button {
  width: 120px;
  height: 40px;
  color: white;
  background-color: #ff6145;
  border-radius: 30px;
  border: 0;
  font-weight: bold;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}
