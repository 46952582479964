.rapper {
  height: 554px;
  border-radius: 50%;
  background-color: rgb(255 255 255);
  position: absolute;
  z-index: -5;
  top: -258px;
  width: 95%;
}

[class*='RaSidebar-root'] {
  background-color: #E3E3E3;
}

.edit-page {
  [class*='RaTopToolbar-root'] {
    display: none;
  }

  .MuiFilledInput-input {
    min-width: 80px;
  }
}
