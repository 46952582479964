.form {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;

  .textarea {
    margin-top: 30px;
    width: 500px;
  }

  .button {
    margin-top: 20px;
    min-width: 128px;
  }
}
