.immunotherapyAnswers__column-wrapper {
  display: flex;
}

.immunotherapyAnswers__column {
  width: 50%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.immunotherapyAnswers__title {
  padding-left: 16px;
  margin: 20px 0;

  h2 {
    margin: 0;
  }
}
